import { useTranslation } from '@loveholidays/phrasebook';

import { getPriceQuoteUnitSuffix } from './PassengerPrice';
import { SuffixType } from './PriceTypes';
import { getPrice, GetPriceParams } from './StaticPrice';
import { DataStoreName, usePassengerPrice } from './usePassengerPrice';
import { useCurrency } from '@Core/prices/useCurrency';
import { usePriceQuoteUnit } from '@Core/prices/usePriceQuoteUnit';

type Params = Omit<GetPriceParams, 'currencyFormat'> & {
  suffixType?: SuffixType;
  store?: DataStoreName;
};

/**
 * useFormattedPrice - return formatted string price
 * you should handle by yourself per-person or total price
 * @returns A formatted string price, EG: '£100'
 *
 * Only use this when you absolutely cannot use any of the price components!
 * This is an escape hatch when using the price components will cause invalid html,
 * for example inside a <option> tag.
 */
export const useFormattedPrice = ({ value, ...params }: Params) => {
  const { t } = useTranslation();
  const currencyFormat = useCurrency();
  const { priceQuoteUnit } = usePriceQuoteUnit();

  const formattedPrice = getPrice({
    ...params,
    currencyFormat,
    value,
  });

  if (!params.suffixType) {
    return formattedPrice;
  }

  const suffix = params.suffixType
    ? t(getPriceQuoteUnitSuffix(priceQuoteUnit, params.suffixType))
    : undefined;

  return `${formattedPrice}${suffix ? ` ${suffix}` : ''}`;
};

/**
 * useFormattedPassengerPrice return formatted string price, per person if needed
 * return price based on the room configuration and per-person or total price preference
 * @returns A formatted string price per person, EG: '£100'
 *
 * Only use this when you absolutely cannot use any of the price components!
 * This is an escape hatch when using the price components will cause invalid html,
 * for example inside a <option> tag.
 * */
export const useFormattedPassengerPrice = ({
  store = 'SEARCH_SELECTION',
  value,
  ...params
}: Params) => {
  const modifiedValue = usePassengerPrice(value, store)!;

  return useFormattedPrice({
    ...params,
    value: modifiedValue,
  });
};
